import { v4 as uuidv4 } from "uuid";
import storage, { EXPIRE_TIME } from "../../../entry/web/storage";

export function getUuid() {
    let uuid;
    if (storage.get("smsUuid")) {
        uuid = storage.get("smsUuid");
    } else {
        uuid = uuidv4();
        storage.set("smsUuid", uuid, EXPIRE_TIME.TODAY);
    }
    return uuid;
}
import moment from "moment";

/**
 * 过期时间枚举
 */
const EXPIRE_TIME = {
  //当天午夜
  TODAY: moment()
    .add(1, "days")
    .startOf("day")
    .unix(),
};

const storage = {

  /**
   * 存储内容
   * @param {string} key
   * @param {any} value
   * @param {int} expire
   * @return void
   */
  set(key, value, expire = 0) {
    localStorage.setItem(key, JSON.stringify({ value, expire }));
  },

  /**
   * 判断（未过期）键值是否存在
   * @param {*} key
   * @return boolean
   */
  has(key) {
    const item = localStorage.getItem(key);
    if (item) {
      const { expire } = JSON.parse(item);
      if (expire == 0 || expire > moment().unix()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  /**
   * 获取内容
   * @param {string}} key
   * @return boolean|any
   */
  get(key) {
    if (this.has(key)) {
      const item = localStorage.getItem(key);
      const { value } = JSON.parse(item);
      return value;
    } else {
      return false;
    }
  },

  /**
   * 清除内容
   * @param {string}} key
   */
  remove(key) {
    localStorage.removeItem(key);
  },
};

export { EXPIRE_TIME };
export default storage;